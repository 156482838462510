





























































































































































































import { Mixins, Watch } from 'vue-property-decorator'
import Component from 'vue-class-component'
import { Action, Getter } from 'vuex-class'
import TheHeader from '@/components/Common/TheHeader.vue'
import TheTitle from '@/components/Common/TheTitle.vue'
import TheSideNavigation from '../components/Common/TheSideNavigation.vue'
import StepperComponent from '@/components/Common/StepperComponent.vue'
import StepOneYoutubeOnboarding from '@/components/Youtube/StepOneYoutubeOnboarding.vue'
import StepTwoYoutubeOnboarding from '@/components/Youtube/StepTwoYoutubeOnboarding.vue'
import StepThreeYoutubeOnboarding from '../components/Youtube/StepThreeYoutubeOnboarding.vue'
import ModalErrorAlert from '@/components/Modals/ModalErrorAlert.vue'
import ModalUploadStatus from '@/components/Modals/ModalUploadStatus.vue'
import isEmpty from 'lodash.isempty'
import { ADORI_SSO_BASE_URL } from '@/constants'
import get from 'lodash.get'
import { QueryClient, useQueryClient } from 'vue-query'
import { feedQueryKeys } from '@/hooks/rssFeeds'
import { audioQueryKeys } from '@/hooks/audio'
import { useGetPublicFeed, youtubeQueryKeys } from '@/hooks/youtube'
import AudioUpload from '@/components/Youtube/AudioUpload.vue'
import Youtube from '@/mixins/Youtube'
import { billingQueryKeys } from '@/hooks/billing'
import Billing from '@/mixins/Billing'
import ViewAudioEditor from './ViewAudioEditor.vue'
import { getQueryParam } from '@/utils/misc'
import mixpanel from 'mixpanel-browser'
import { useGetCustomTemplateStyles, useGetSubtitleTemplates } from '@/hooks/subtitle'

// @ts-ignore
const webengage = window['webengage']

Component.registerHooks(['beforeRouteEnter', 'beforeRouteLeave', 'beforeRouteUpdate'])

@Component({
  components: {
    TheHeader,
    TheTitle,
    TheSideNavigation,
    StepperComponent,
    StepOneYoutubeOnboarding,
    StepTwoYoutubeOnboarding,
    StepThreeYoutubeOnboarding,
    ModalUploadStatus,
    ModalErrorAlert,
    AudioUpload,
    ViewAudioEditor,
  },
  setup() {
    const queryClient = useQueryClient()
    useGetCustomTemplateStyles()
    useGetSubtitleTemplates()
    const { mutate: getPublicFeed, isLoading: isPublicFeedLoading } = useGetPublicFeed()
    return {
      queryClient,
      getPublicFeed,
      isPublicFeedLoading,
    }
  },
})
export default class ViewOnboarding extends Mixins(Youtube, Billing) {
  @Getter publicFeed!: any
  @Getter publicFeedEpisodes!: any
  @Getter selectedEpisodeList!: any
  @Getter youtubeStep!: any
  @Getter networkId!: string
  @Getter selectedEpisodeSettings!: any
  @Getter uploadListStatus!: any
  @Getter uploadTrackTrigger!: any
  @Getter triggerLogin!: any
  @Getter selectedRssEpisodes!: any
  @Getter getRssEpisodeByGuid!: any
  @Getter signinStatus!: any
  @Getter youtubeProfile!: any
  @Getter callUpload!: any
  @Getter callPublish!: any
  @Getter createdYoutubeFeed!: any
  @Getter networkType!: any
  @Getter selectedRssFeedUid!: any
  @Getter isYoutube!: Boolean

  @Action checkAudioUpload!: any
  @Action setSession!: any
  @Action loadUser!: any
  @Action youtubeUpload!: any
  @Action loadYoutubeUser!: any
  @Action ytModalSetting!: any
  @Action pushNotification!: any
  @Action createYoutubeFeed!: any
  @Action closeModal!: any
  @Action clearUploadListStatus!: any
  @Action showYTBatchSetting!: Function
  @Action toggleCreditCardAlert!: Function

  modalAlertStatus = false
  episodeErrObj: any[] = []

  loading = false
  youtubeAuthHandle: any = null
  youtubeAuthWindow: any = null
  youtubeLoader = false
  PublishText = 'Publish'
  intent = 'PUBLISH_TO_YOUTUBE' // One of "DOWNLOAD_VIDEO" OR "PUBLISH_TO_YOUTUBE"
  nextStepDisabled = false
  unUploadedTracks = []

  queryClient!: QueryClient
  isPublicFeedLoading!: boolean
  getPublicFeed!: any
  publish = false
  audioEditor = false

  beforeRouteLeave(to: any, from: any, next: any) {
    if (this.uploadListIsEmpty) {
      this.$store.dispatch('showConfirm', {
        title: 'Audio Processing...',
        description: 'Data will be lost! Are you sure you want to go ahead?',
        yesButtonText: 'Yes',
        noButtonText: 'No',
        onConfirm: async () => {
          this.closeModal()
          this.clearUploadListStatus()
          next()
        },
        onCancel: async () => {
          this.closeModal()
          next(false)
        },
      })
    } else {
      next()
    }
  }

  async created() {
    sessionStorage.getItem('feedUrl') && this.getPublicFeed({ url: sessionStorage.getItem('feedUrl') })
    if (this.youtubeStep === 2) window.addEventListener('beforeunload', this.reload)
    this.$store.commit('clearUploadCounter')
    this.youtubeShowSignInStatus && (this.podcastId = this.ytFeedUid)

    if (this.selectedRssEpisodes.length === 0 && [2, 3].includes(this.youtubeStep) && !this.uploadListIsEmpty) {
      this.$store.commit('setCallUpload', true)
      this.$store.commit('setUploadTrackTrigger')
    }
    if (getQueryParam('uid')) {
      this.audioEditor = true
    } else this.audioEditor = false
    this.$watch(
      () => this.$route,
      () => {
        if (getQueryParam('uid')) {
          this.audioEditor = true
        } else this.audioEditor = false
      }
    )
  }

  removeOnload() {
    window.removeEventListener('beforeunload', this.reload)
  }

  reload(event: BeforeUnloadEvent) {
    event.preventDefault()
    return (event.returnValue = 'Changes that you made may not be saved')
  }

  beforeDestroy() {
    window.removeEventListener('beforeunload', this.reload)
  }

  beforeCreate() {
    if (localStorage.getItem('networkId') && !sessionStorage.getItem('upload') && !sessionStorage.getItem('feedUrl')) {
      this.$router.push(`/`)
    }
  }

  get isBlogNetwork() {
    return ['VIDEO_FREE', 'VIDEO_BASIC', 'VIDEO_PRO'].includes(this.networkType)
  }

  get stepsLabel() {
    const list = [
      { initially: 'Edit Audio', onSuccess: 'Audio Edited' },
      { initially: 'Publish', onSuccess: 'published' },
    ]
    !this.isBlogNetwork && list.push({ initially: 'Select Audio', onSuccess: 'Audio Selected' })
    return list
  }

  get youtubeSignInStatus() {
    return this.ytFeedUid && this.signinStatus[this.ytFeedUid]?.youtube
      ? this.signinStatus[this.ytFeedUid]?.youtube
      : this.signinStatus[this.networkId]?.youtube
      ? this.signinStatus[this.networkId]?.youtube
      : false
  }

  get youtubeNetworkSignInStatus() {
    return this.signinStatus[this.networkId]?.youtube || false
  }
  get youtubeShowSignInStatus() {
    return (this.ytFeedUid && this.signinStatus[this.ytFeedUid]?.youtube) || false
  }

  get feedPreviewImage() {
    return this.episodes[0] ? this.episodes[0].imageUrl : null
  }

  get uploadListIsEmpty() {
    if (isEmpty(this.uploadListStatus)) return false
    else {
      const status = Object.values(this.uploadListStatus)
      if (status.includes(true)) return true
      else return false
    }
  }

  get stepperProgress() {
    return (100 / 2) * (this.youtubeStep - 1) + '%'
  }

  get showHomeLink() {
    return this.networkId ? true : false
  }

  goToHome() {
    this.isYoutube
      ? this.ytFeedUid
        ? this.$router.push(`/home/podcasts/${this.ytFeedUid}`)
        : this.$router.push('/home/all')
      : this.$router.push(`/audio/podcasts/${sessionStorage.getItem('feedUid')}`)
  }

  changeStep(step: number) {
    this.$store.commit('setYoutubeStep', step)
    if (this.youtubeStep === 1) {
      window.removeEventListener('beforeunload', this.reload)
      this.$store.commit('clearYoutubeState')
    }
  }

  nextStep() {
    this.$store.commit('setYoutubeStep', this.youtubeStep + 1)
    if (this.youtubeStep === 2 && this.networkId) {
      window.addEventListener('beforeunload', this.reload)
      this.$store.commit('setCallUpload', true)
      this.$store.commit('setUploadTrackTrigger')
    }
  }

  previousStep() {
    this.$store.commit('setYoutubeStep', this.youtubeStep - 1)
    if (this.youtubeStep === 1) {
      window.removeEventListener('beforeunload', this.reload)
      this.$store.commit('clearYoutubeState')
    }
  }

  get handleNext() {
    if (this.selectedEpisodeList.length === 0 || this.nextStepDisabled) return true
    else false
  }

  get handlePrevious() {
    return (
      (this.totalUploads !== this.perUpload && this.youtubeStep !== 3) ||
      (this.selectedEpisodeList[0] &&
        [true, false].includes(this.selectedEpisodeList[0].isRss) &&
        this.youtubeStep === 2)
    )
  }

  get disablePublish() {
    if (Object.values(this.uploadListStatus).includes(true)) return true
    else false
  }

  get show() {
    return this.publicFeed
  }

  get episodes() {
    return this.publicFeedEpisodes
  }

  get totalUploads() {
    return Object.values(this.uploadListStatus).length
  }

  get perUpload() {
    const uploads = Object.values(this.uploadListStatus)
    return uploads.filter((element: any) => element === false).length
  }

  get ytFeedUid() {
    return get(this.createdYoutubeFeed, 'uid', '') || sessionStorage.getItem('feedUid')
  }

  handleSearch() {
    this.$store.commit('setCurrentModal', 'importAndUpload')
  }

  async uploadToYoutube() {
    try {
      this.pushNotification({
        text: `Publishing in progress. Please wait or head to the My videos section`,
        type: 'SUCCESS',
      })
      const delayIncrement = 1000
      let delay = 0
      const uploadArr = Object.keys(this.selectedEpisodeSettings).map((guid: string, index: number) => {
        delay += index * delayIncrement
        return new Promise((resolve) => setTimeout(resolve, delay)).then(async () => {
          if (this.selectedEpisodeList.length === 1 && this.selectedEpisodeList[0].isRss === false)
            guid = this.selectedEpisodeList[0].guid
          let brandDescription
          if (
            !this.selectedEpisodeSettings[guid].customDescription &&
            this.selectedEpisodeSettings[guid].chapterMarkers
          ) {
            brandDescription =
              this.selectedEpisodeSettings[guid].description +
              '\n\nPowered by Adori. https://adorilabs.com, #AdoriLabs' +
              this.selectedEpisodeSettings[guid].chapterMarkers
          } else {
            brandDescription = this.selectedEpisodeSettings[guid].description
            //   +'\n\nPowered by Adori. https://adorilabs.com, #AdoriLabs'
          }

          const uploadType =
            (this.selectedEpisodeSettings[guid].hasTags || this.selectedEpisodeSettings[guid].hasAudiograms) &&
            this.selectedEpisodeSettings[guid].includeVisuals
              ? 'INTERACTIVE'
              : 'NON_INTERACTIVE'

          const trackUid =
            this.selectedEpisodeList[0].isRss === false
              ? this.selectedRssEpisodes[0].uid
              : this.getRssEpisodeByGuid(guid).uid

          sessionStorage.setItem(trackUid, trackUid)

          let commonPayload: any = {
            trackUid: trackUid,
            categoryId: this.selectedEpisodeSettings[guid].category,
            privacy: this.selectedEpisodeSettings[guid].privacy,
            title: this.selectedEpisodeSettings[guid].title,
            description: brandDescription,
            keywords: this.selectedEpisodeSettings[guid].keywords,
            width: this.selectedEpisodeSettings[guid].width,
            height: this.selectedEpisodeSettings[guid].height,
            startTimeSec: this.selectedEpisodeSettings[guid].startTimeSec,
            endTimeSec: this.selectedEpisodeSettings[guid].isFullDuration
              ? null
              : this.selectedEpisodeSettings[guid].endTimeSec,
            type: uploadType,
            posterImageId: this.selectedEpisodeSettings[guid].posterImageId,
            selfDeclareMadeForKids: this.selectedEpisodeSettings[guid].isMadeForKids,
            burnSubtitle: this.selectedEpisodeSettings[guid].burnSubtitle,
            backgroundVideo: this.selectedEpisodeSettings[guid].backgroundVideo,
          }

          if (this.selectedEpisodeSettings[guid].hasAudiograms) {
            const audiogramId = this.$store.getters.audiogramPositions(trackUid)[0].audiogramId
            commonPayload = { ...commonPayload, audiogramId: audiogramId }
          }

          get(this.selectedEpisodeSettings[guid], 'playlistId', 0) !== 0 &&
            (commonPayload['playlistId'] = get(this.selectedEpisodeSettings[guid], 'playlistId'))

          if (this.selectedEpisodeSettings[guid].scheduledFor) {
            const payload: any = {
              data: {
                ...commonPayload,
                scheduledFor: this.selectedEpisodeSettings[guid].scheduledFor,
              },
              params: {
                network_id: this.networkId,
              },
            }
            this.signinStatus[this.ytFeedUid]?.youtube && (payload.params['showUid'] = this.ytFeedUid)
            await this.youtubeUpload(payload)
          } else {
            const payload: any = {
              data: {
                ...commonPayload,
              },
              params: {
                network_id: this.networkId,
              },
            }
            this.signinStatus[this.ytFeedUid]?.youtube && (payload.params['showUid'] = this.ytFeedUid)
            await this.youtubeUpload(payload)
          }
        })
      })

      await Promise.all(uploadArr)

      this.$store.commit('setUploadState')
      this.$store.commit('clearUploadCounter')
      this.$gtag.event('publish-youtube', {
        event_category: 'User Interaction',
        event_label: 'Published to youtube',
        value: uploadArr.length,
      })
      mixpanel.track('Publish to Youtube')
      webengage?.track('publish-youtube', { noOfVideos: uploadArr.length })
      this.queryClient.invalidateQueries(billingQueryKeys.BILLING_USAGE)
      if (this.isYoutube) {
        if (this.createdYoutubeFeed.uid) {
          this.queryClient.invalidateQueries([audioQueryKeys.YTPUBLISHED, `${this.createdYoutubeFeed.uid}`])
          this.queryClient.invalidateQueries([audioQueryKeys.YTUNPUBLISHED, `${this.createdYoutubeFeed.uid}`])
        } else this.queryClient.invalidateQueries(audioQueryKeys.ALLTRACKS)
        this.queryClient.invalidateQueries(billingQueryKeys.BILLING_INVOICES)
        this.$store.commit('clearAllPaginationState')
        if (this.ytFeedUid) {
          this.$router.push(`/home/podcasts/${this.ytFeedUid}`)
        } else {
          this.$router.push('/home/all')
        }
      } else {
        this.queryClient.invalidateQueries([audioQueryKeys.YTPUBLISHED, `${this.createdYoutubeFeed.uid}`])
        this.queryClient.invalidateQueries(audioQueryKeys.ALLTRACKS)
        this.$store.commit('clearAllPaginationState')
        if (this.ytFeedUid) {
          this.$router.push(`/audio/podcasts/${this.ytFeedUid}`)
        } else if (sessionStorage.getItem('feedUid')) {
          this.$router.push(`/audio/podcasts/${sessionStorage.getItem('feedUid')}`)
        } else {
          this.$router.push('/audio/all')
        }
      }
    } catch (error) {
      this.youtubeLoader = false
    }
  }

  checkErrorMiddleware() {
    let episodeErrObj: any[] = []
    Object.keys(this.selectedEpisodeSettings).forEach((guid: string, index: number) => {
      if (this.selectedEpisodeList.length === 1 && this.selectedEpisodeList[0].isRss === false)
        guid = this.selectedEpisodeList[0].guid
      let brandDescription,
        isTitleCharacterExceeded = false,
        isDescriptionCharacterExceeded = false
      if (!this.selectedEpisodeSettings[guid].customDescription && this.selectedEpisodeSettings[guid].chapterMarkers) {
        brandDescription =
          this.selectedEpisodeSettings[guid].description +
          '\n\nPowered by Adori. https://adorilabs.com, #AdoriLabs' +
          this.selectedEpisodeSettings[guid].chapterMarkers
      } else {
        brandDescription =
          this.selectedEpisodeSettings[guid].description + '\n\nPowered by Adori. https://adorilabs.com, #AdoriLabs'
      }

      if (99 - this.selectedEpisodeSettings[guid].title.length < 0) {
        isTitleCharacterExceeded = true
      }

      if (4999 - brandDescription.length < 0) {
        isDescriptionCharacterExceeded = true
      }

      if (isTitleCharacterExceeded || isDescriptionCharacterExceeded) {
        episodeErrObj.push({
          guid: guid,
          isTitleCharacterExceeded: isTitleCharacterExceeded,
          isDescriptionCharacterExceeded: isDescriptionCharacterExceeded,
          title: this.selectedEpisodeSettings[guid].title,
          description: brandDescription,
          active: index == 0 ? true : false,
        })
      }
    })
    return episodeErrObj
  }

  batchSetting() {
    this.showYTBatchSetting()
  }

  handleBeforePublish() {
    if (!this.youtubeSignInStatus) {
      this.youtubeAuthWindow = window.open('', 'youtubeAuthHandle', 'width=500,height=715')
    }
    this.episodeErrObj = this.checkErrorMiddleware()
    if (this.episodeErrObj.length) {
      this.modalAlertStatus = true
    } else {
      this.handlePublish()
    }
  }

  async handlePublish() {
    if (this.networkId) {
      if (this.showPopup) {
        this.toggleCreditCardAlert(true)
        return 0
      }
      const youtubePayload: any = { networkId: this.networkId }
      this.signinStatus[this.ytFeedUid]?.youtube && (youtubePayload['showId'] = this.ytFeedUid)
      if (this.youtubeSignInStatus) {
        this.youtubeLoader = true
        this.PublishText = 'Publishing..'
        this.youtubeAuthWindow && this.youtubeAuthWindow.close()
        this.uploadToYoutube()
      } else {
        this.triggerYoutubeLogin(true)
      }
    } else {
      this.$store.commit('setTriggerLogin')
      this.$store.commit('setCallUpload', true)
      this.$store.commit('setCallPublish', true)
    }
  }

  async onYtLoginSuccess() {
    this.youtubeLoader = false
    this.PublishText = 'Publish'
    sessionStorage.removeItem('showLogin')
    this.publish && this.handlePublish()
    this.publish = false
  }

  triggerYoutubeLogin(publish = false) {
    this.youtubeLoader = true
    this.podcastId = sessionStorage.getItem('showLogin') ? this.ytFeedUid : undefined
    this.showId = this.podcastId
    this.signIn()
    this.publish = publish
  }

  @Watch('ytFeedUid')
  handleYtFeed() {
    this.podcastId = this.ytFeedUid
  }

  @Watch('youtubeStep')
  handleYoutubeStep() {
    if (
      this.selectedEpisodeList[0].isRss === false &&
      this.selectedRssEpisodes.length === 0 &&
      this.youtubeStep === 2 &&
      this.networkId &&
      !this.uploadListIsEmpty
    ) {
      this.$store.commit('setCallUpload', true)
      this.$store.commit('setUploadTrackTrigger')
    }
  }

  @Watch('selectedRssEpisodes')
  episodeChanged() {
    this.selectedRssEpisodes.map((obj: any) => {
      if (this.selectedEpisodeList.length === 1 && this.selectedEpisodeList[0].isRss === false) {
        this.ytModalSetting({
          guid: this.selectedEpisodeList[0].guid,
          posterImageId: obj.imageId,
        })
      } else {
        if (!this.selectedEpisodeSettings[obj.guid].posterImageId) {
          this.ytModalSetting({
            guid: obj.guid,
            posterImageId: obj.imageId,
          })
        }
      }
      if (
        this.selectedEpisodeList.filter((obj: any) => obj.guid)[0]?.durationSeconds ===
        this.selectedEpisodeSettings[obj.guid]?.endTimeSec
      ) {
        this.ytModalSetting({
          guid: obj.guid,
          endTimeSec: obj.durationMillis / 1000,
        })
      }
    })
  }

  async removeEpisode() {
    this.unUploadedTracks = []
    if (!this.uploadListIsEmpty && this.callUpload) {
      if (this.callPublish) {
        this.$store.commit('setCallPublish', false)
        await this.handlePublish()
      }
      this.$store.commit('setCallTags', true)
      this.$store.commit('setCallUpload', false)
    }
  }

  @Watch('uploadTrackTrigger')
  async handleUploadTrigger() {
    if (this.uploadTrackTrigger) {
      if (
        this.selectedEpisodeList.length > 0 &&
        this.selectedEpisodeList[0].isRss !== false &&
        [2, 3].includes(this.youtubeStep)
      ) {
        this.nextStepDisabled = true
        const guids = this.selectedEpisodeList.map((obj: any) => obj.guid)
        const params = new URLSearchParams()
        guids.map((id: string) => {
          params.append('by_guid', id)
        })
        let payload = {
          networkId: this.networkId,
          params: params,
        }
        const res = await this.checkAudioUpload(payload)
        this.$store.commit('setMultipleRssEpisodes', res)

        const resGuids = res.map((obj: any) => obj.guid)
        const unUploadedTrack = guids.reduce((acc: any, id: any) => {
          if (!resGuids.includes(id)) {
            const obj = this.selectedEpisodeList.filter((obj: any) => obj.guid === id)[0]
            acc.push(obj)
          }
          return acc
        }, [])

        if (sessionStorage.getItem('feedUrl') && !this.selectedEpisodeList[0].isRss && this.isYoutube) {
          const feedPayload = {
            networkId: this.networkId,
            data: {
              rss: sessionStorage.getItem('feedUrl'),
              imageUrl: this.feedPreviewImage,
            },
          }
          await this.createYoutubeFeed(feedPayload)
          this.queryClient.invalidateQueries(feedQueryKeys.YTFEED)
        }

        this.nextStepDisabled = false
        this.unUploadedTracks = unUploadedTrack.map((obj: any) =>
          sessionStorage.getItem('feedUrl') ? { ytFeedUid: this.ytFeedUid, ...obj } : obj
        )
      } else if (this.youtubeStep === 2) {
        this.nextStepDisabled = true
        if (this.selectedEpisodeList[0].isReUpload) {
          const params = new URLSearchParams()
          params.append('by_guid', this.selectedEpisodeList[0].guid)
          let payload = {
            networkId: this.networkId,
            params: params,
          }
          const res = await this.checkAudioUpload(payload)
          this.$store.commit('setMultipleRssEpisodes', res)
        } else {
          this.unUploadedTracks = this.selectedEpisodeList
          this.queryClient.invalidateQueries(audioQueryKeys.ALLTRACKS)
        }
        this.nextStepDisabled = false
      }
    }
  }

  @Watch('triggerLogin')
  async handleTriggerLogin() {
    if (this.triggerLogin) {
      this.youtubeStep === 3 && sessionStorage.setItem('youtubeAuth', 'YES')

      if (this.networkId) {
        this.triggerYoutubeLogin()
        return
      } else {
        this.youtubeAuthHandle = window.open(
          `${ADORI_SSO_BASE_URL}/logout?redirect_uri=${encodeURIComponent(window.origin + '/signup')}`,
          'youtubeAuthHandle',
          'width=500,height=715'
        )
      }

      const timer = setInterval(async () => {
        if (get(this.youtubeAuthHandle, 'closed', null)) {
          clearInterval(timer)
          if (localStorage.getItem('id_token')) {
            this.youtubeStep === 3 && sessionStorage.removeItem('youtubeAuth')
            const token: any = localStorage.getItem('id_token')
            const expiry: any = localStorage.getItem('expires_at')
            this.setSession({
              token: token,
              expiry: expiry,
            })
            this.$store.commit('setInitialLoader', true)
            await this.loadUser()
            this.$store.commit('setInitialLoader', false)
            this.$store.commit('setUploadTrackTrigger')
            this.queryClient.invalidateQueries([
              youtubeQueryKeys.YOUTUBE_SUMMARY,
              this.networkId,
              { showId: this.showId },
            ])
          }
        }
      }, 1000)
    }
  }
}
